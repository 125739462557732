import React, { useEffect, useState } from "react";
import "./Devices.css";
import { useTheme } from "../theme/ThemeContext";
import DataTable from "react-data-table-component";

const Devices = () => {

  const backendurl = window.location.hostname === 'localhost'
    ? ''
    : process.env.REACT_APP_BACKEND_URL;


  const { theme } = useTheme();
  const [showPopup, setShowPopup] = useState(false);
  const [editData, setEditData] = useState(null);
  const [devices, setDevices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [addPopup, setAddPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false)
  const [rawMaterials, setRawMaterials] = useState([]);
  const [components, setComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [requiredQty, setRequiredQty] = useState("");
  const [modelId, setModelId] = useState(null);
  const [actionPopup, setActionPopup] = useState(false); // New state for the action popup
  const [actionData, setActionData] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [Qty, setQty] = useState("");
  const [showrawDeletePopup, setShowrawDeletePopup] = useState(false); // State to control popup visibility
  const [selectedRawMaterial, setSelectedRawMaterial] = useState(null); // State to store selected raw material
  const [deleterawMaterials, setDeleteRawMaterials] = useState([]);

  const handlerawDeleteClick = (row) => {
    setSelectedRawMaterial(row); // Store the selected raw material
    setShowrawDeletePopup(true); // Show the delete confirmation popup
  };

  const handleClosePopup = () => {
    setShowrawDeletePopup(false); // Close the popup
  };

  const handlerawDeleteConfirm = async () => {
    if (!selectedRawMaterial) return;

    try {
      // Make DELETE request to API
      const response = await fetch(`${backendurl}/dashboard/api/delete/thingrawmaterials/${selectedRawMaterial.id}`, {
        method: 'DELETE',
      });

      const result = await response.json();

      if (response.ok) {
        // Remove the deleted raw material from the state
        setDeleteRawMaterials((prevMaterials) =>
          prevMaterials.filter((material) => material.id !== selectedRawMaterial.id)
        );
        setShowrawDeletePopup(false); // Close the popup
      } else {
        console.error('Error:', result.message);
        // Handle error (e.g., show a notification or message to the user)
      }
    } catch (error) {
      console.error('Error deleting raw material:', error);
      // Handle fetch error (e.g., show a notification or message to the user)
    }
  };




  const [newModel, setNewModel] = useState({
    model: "",
    mrp: "",
    retail_price: "",
    sgst: "",
    cgst: "",
    igst: "",
    discount: "",
    warranty_period: "",
  });


  const handleEditClick = (row) => {
    setActionPopup(true);
    setActionData(row); // Pass the data of the row being edited
  };

  // Handle delete button click
  const handleDeleteClick = (row) => {
    setDeletePopup(true);
    setDeleteData(row); // Pass the data of the row being deleted
  };

  // Close action popup
  // const handleCloseActionPopup = () => {
  //   setActionPopup(false);
  //   setActionData(null);
  // };

  const fetchDevices = async (query = "") => {
    try {
      const response = await fetch(`${backendurl}/dashboard/api/display/prices-table?search=${query}`);
      const data = await response.json();
      setDevices(data);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };


  const fetchRawMaterials = async (modelId) => {
    console.log("Fetching raw materials for modelId:", modelId); // Debug
    try {
      const response = await fetch(`${backendurl}/dashboard/api/model/${modelId}`);
      const data = await response.json();
      if (response.ok) {
        setRawMaterials(data.raw_materials);
        setModelId(modelId);
        console.log("Opening edit popup..."); // Debug
        setEditPopup(true); // Opens the popup
        console.log("editPopup changed:", editPopup);
      } else {
        console.error("Error fetching raw materials:", data.error);
      }
    } catch (error) {
      console.error("Error fetching raw materials:", error);
    }
  };


  const handleSaveActionPopup = async () => {
    if (!editData) {
      console.error("No data to update. Please check if editData is properly set.");
      return; // Prevent function from continuing if editData is null
    }

    const { modelId, rawMaterialId } = editData;

    // Validate required quantity
    if (!Qty || Qty <= 0) {
      alert("Please provide a valid quantity greater than zero.");
      return;
    }

    console.log("Saving data:", { Qty, modelId, rawMaterialId }); // Debugging

    try {
      const response = await fetch(`${backendurl}/dashboard/api/update/raw/${modelId}/${rawMaterialId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requiredQty: Qty }), // Send the requiredQty from state
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Failed to update raw material: ${errorMessage}`);
      }

      const updatedMaterial = await response.json();
      console.log("Updated material:", updatedMaterial); // Debugging the response

      setMaterials((prevMaterials) =>
        prevMaterials.map((material) =>
          material.rawMaterialId === updatedMaterial.data.raw_material_id &&
            material.modelId === updatedMaterial.data.model_id
            ? { ...material, required_qty: updatedMaterial.data.required_qty }
            : material
        )
      );

      // Close the action popup after successful save
      handleCloseActionPopup();
    } catch (error) {
      console.error("Error updating raw material:", error);
    }
  };

  // Function to close the action popup
  const handleCloseActionPopup = () => {
    setActionPopup(false);
    setRequiredQty(""); // Clear the input field when closing the popup
  };




  useEffect(() => {
    const fetchComponents = async () => {
      console.log(`url${backendurl}`)
      try {
        const response = await fetch(`${backendurl}/dashboard/api/raw_materials`);
        const data = await response.json();
        console.log("Fetched components data:", data); // Debug here
        console.log("Fetched components:", data.raw_materials); // Debug here
        setComponents(data.raw_materials || []);

      } catch (error) {
        console.error("Error fetching components:", error);
      }
    };

    fetchComponents();
  }, []);

  const handleAddRawMaterial = async (modelid) => {
    // if (!selectedComponent || !requiredQty) {
    //   alert("Please select a component and enter the required quantity.");
    //   return;
    // }

    try {
      console.log(`url${backendurl}`)
      const response = await fetch(`${backendurl}/dashboard/api/model/${modelid}/add-raw-material`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          raw_material_id: selectedComponent, // Assuming this is the ID
          required_qty: requiredQty,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        alert("Raw material added successfully!");
      }
      //   // Fetch the updated list of raw materials or update it manually
      //   const updatedRawMaterials = await fetch(`${backendurl}/dashboard/api/model/${modelid}/raw-materials`);
      //   const rawMaterialsData = await updatedRawMaterials.json();
      //   setRawMaterials(rawMaterialsData);
      // } else {
      //   const error = await response.json();
      //   alert(error.error || "Failed to add raw material");
      // }
    } catch (err) {
      console.error("Error adding raw material:", err);
      alert("An error occurred while adding the raw material.");
    }
  };

  useEffect(() => {
    fetchDevices(searchQuery);
  }, [searchQuery]);

  const handleEdit = (row) => {
    setEditData(row);
    setShowPopup(true);
  };

  const handleEditPopup = (row) => {
    setEditData(row);
    setEditPopup(true)
  }


  const handleDeleteClickee = (row) => {
    setDeleteData(row);
    setDeletePopup(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await fetch(`${backendurl}/dashboard/api/delete/price_table/${deleteData.id}`, {
        method: "DELETE",
      });
      setDevices(devices.filter((device) => device.id !== deleteData.id));
      setDeletePopup(false);
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };


  const handleCloseDeletePopup = () => {
    setDeletePopup(false);
    setDeleteData(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const validateWarrantyPeriod = (warranty) => {
    const validFormat = /^(?:(\d+)\s+years?)?\s*(?:(\d+)\s+months?)?$/i;
    return validFormat.test(warranty);
  };

  const handleSaveEdit = async () => {
    const { id, mrp, retail_price, discount, sgst, cgst, igst, warranty_period } = editData;

    if (!validateWarrantyPeriod(warranty_period)) {
      alert("Invalid warranty period format. Use formats like '2 years', '6 months', or '1 year 6 months'.");
      return;
    }

    if (!sgst || !cgst || !igst) {
      alert("Please provide valid tax values.");
      return;
    }

    try {
      const response = await fetch(`${backendurl}/dashboard/api/update/price_table/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mrp, retail_price, discount, sgst, cgst, igst, warranty_period }),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Failed to update device: ${errorMessage}`);
      }

      const updatedDevice = await response.json();
      setDevices((prevDevices) =>
        prevDevices.map((device) => (device.id === updatedDevice.id ? updatedDevice : device))
      );
      setShowPopup(false);
      setEditData(null);
    } catch (error) {
      console.error("Error updating device:", error);
    }
  };




  const handleAddNewModel = async () => {
    console.log("New Model Data:", newModel); // Debugging

    try {
      const response = await fetch(`${backendurl}/dashboard/api/create/price_table`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newModel),
      });

      if (!response.ok) {
        throw new Error("Failed to add new model");
      }

      const addedDevice = await response.json();
      setDevices([...devices, addedDevice]);
      setAddPopup(false);
      setNewModel({
        model: "",
        mrp: "",
        retail_price: "",
        sgst: "",
        cgst: "",
        igst: "",
        discount: "",
        warranty_period: "",
      });
    } catch (error) {
      console.error("Error adding new model:", error);
    }
  };






  const columns = [
    {
      name: 'ID',
      selector: (row, index) => index + 1, sortable: true
    },
    { name: "Model", selector: (row) => row.model, sortable: true },
    { name: "MRP", selector: (row) => row.mrp, sortable: true },
    { name: "Retail Price", selector: (row) => row.retail_price, sortable: true },
    { name: "sgst", selector: (row) => row.sgst, sortable: true },
    { name: "cgst", selector: (row) => row.cgst, sortable: true },
    { name: "igst", selector: (row) => row.igst, sortable: true },
    { name: "Discount", selector: (row) => row.discount, sortable: true },
    { name: "Last Modification", selector: (row) => row.lastmodified, sortable: true },
    {
      name: "Warranty",
      selector: (row) => {
        let warranty = row.warranty_period;

        // Validate the warranty_period as an object
        if (typeof warranty === 'object' && warranty !== null) {
          const years = warranty.years || 0;
          const months = warranty.months || 0;

          // Construct the warranty display string
          if (years > 0 && months > 0) {
            return `${years} years ${months} months`;
          } else if (years > 0) {
            return `${years} years`;
          } else if (months > 0) {
            return `${months} months`;
          } else {
            return 'No warranty';
          }
        }

        // Handle cases where warranty_period is not an object
        return 'Invalid warranty data';
      },
      sortable: true,
    },
    {
      name: "Raw Materials",
      cell: (row) => (
        <button className="raw-button" onClick={() => fetchRawMaterials(row.id)}>
          <i class="fa-solid fa-screwdriver-wrench"></i>
        </button>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="device-action-btn">
          <button className="action-button" onClick={() => handleEdit(row)}>
            <i className="fa-solid fa-pen-to-square"></i>
          </button>
          <button className="action-button" onClick={() => handleDeleteClick(row)}>
            <i className="fa-solid fa-trash-can"></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className={`device-main ${theme}-theme`}>
      <div className="dash-dummy"></div>
      <div className="second-main">


        <div className="device-content-main">
          <div className="device-content">
            <div className="device-content-head">
              <p>Device Details</p>
            </div>
            <div className="device-content-items">
              <div className="device-search">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  type="search"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              <i
                className="fa-regular fa-square-plus add-model-icon"
                onClick={() => setAddPopup(true)}
              ></i>
            </div>
            {showPopup && (
              <div className="model-edit-popup-overlay">
                <div className="model-edit-popup-content">
                  <button
                    className="model-edit-popup-close"
                    type="button"
                    onClick={() => setShowPopup(false)}
                  >
                    <i className="fa-solid fa-x"></i>
                  </button>
                  <form className="model-edit-popup-input">
                    <div>
                      <label>MRP:</label> <br />
                      <input
                        type="text"
                        value={editData?.mrp || ""}
                        onChange={(e) => setEditData({ ...editData, mrp: e.target.value })}
                      />
                    </div>
                    <div>
                      <label>Retail Price:</label> <br />
                      <input
                        type="text"
                        value={editData?.retail_price || ""}
                        onChange={(e) => setEditData({ ...editData, retail_price: e.target.value })}
                      />
                    </div>
                    <div>
                      <label>Discount:</label> <br />
                      <input
                        type="text"
                        value={editData?.discount || ""}
                        onChange={(e) => setEditData({ ...editData, discount: e.target.value })}
                      />
                    </div>
                    <div className="model-add-input-tax">
                      <div className="sgst tax">
                        <label>SGST:</label>
                        <input
                          type="number"
                          value={editData?.sgst || ""}
                          onChange={(e) => setEditData({ ...editData, sgst: e.target.value })}
                        />
                      </div>
                      <div className="cgst tax">
                        <label>CGST:</label>
                        <input
                          type="number"
                          value={editData?.cgst || ""}
                          onChange={(e) => setEditData({ ...editData, cgst: e.target.value })}
                        />
                      </div>
                      <div className="igst tax">
                        <label>IGST:</label>
                        <input
                          type="number"
                          value={editData?.igst || ""}
                          onChange={(e) => setEditData({ ...editData, igst: e.target.value })}
                        />
                      </div>
                    </div>
                    <div>
                      <label>Warranty:</label> <br />
                      <input
                        type="text"
                        value={editData?.warranty_period || ""}
                        onChange={(e) => setEditData({ ...editData, warranty_period: e.target.value })}
                      />
                    </div>
                  </form>
                  <button
                    type="button"
                    className="model-edit-popup-save"
                    onClick={handleSaveEdit}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}

          </div>
          <DataTable columns={columns} data={devices} fixedHeader pagination />



          {deletePopup && (
            <div className="delete-popup-overlay">
              <div className="delete-popup-content">
                <p>Are you sure you want to delete this device?</p>
                <div className="delete-popup-btn">
                  <button className="delete-popup-confirm" onClick={handleDeleteConfirm}>
                    Confirm
                  </button>
                  <button className="delete-popup-cancel" onClick={handleCloseDeletePopup}>
                    Cancel
                  </button>
                </div>

              </div>
            </div>
          )}

          {addPopup && (
            <div className="model-popup-overlay">
              <div className="model-popup-content">
                <button className="model-popup-close" onClick={() => setAddPopup(false)}>
                  <i className="fa-solid fa-x"></i>
                </button>
                <h3>Add New Model</h3>
                <form>
                  <div className="model-add-input">
                    <label>Model :</label>
                    <input
                      type="text"
                      value={newModel.model}
                      onChange={(e) => setNewModel({ ...newModel, model: e.target.value })}
                    />
                  </div>

                  <div className="model-add-input">
                    <label>MRP :</label>
                    <input
                      type="number"
                      value={newModel.mrp}
                      onChange={(e) => setNewModel({ ...newModel, mrp: e.target.value })}
                    />
                  </div>

                  <div className="model-add-input">
                    <label>Retail Price :</label>
                    <input
                      type="number"
                      value={newModel.retail_price}
                      onChange={(e) => setNewModel({ ...newModel, retail_price: e.target.value })}
                    />
                  </div>
                  <div className="model-add-input-tax">
                    <div className="sgst tax">
                      <label>SGST:</label>
                      <input
                        type="number"
                        value={newModel.sgst}
                        onChange={(e) => setNewModel({ ...newModel, sgst: e.target.value })}
                      />
                    </div>
                    <div className="cgst tax">
                      <label>CGST:</label>
                      <input
                        type="number"
                        value={newModel.cgst}
                        onChange={(e) => setNewModel({ ...newModel, cgst: e.target.value })}
                      />
                    </div>
                    <div className="igst tax">
                      <label>IGST:</label>
                      <input
                        type="number"
                        value={newModel.igst}
                        onChange={(e) => setNewModel({ ...newModel, igst: e.target.value })}
                      />
                    </div>
                  </div>



                  <div className="model-add-input">
                    <label>Discount :</label>
                    <input
                      type="number"
                      value={newModel.discount}
                      onChange={(e) => setNewModel({ ...newModel, discount: e.target.value })}
                    />
                  </div>

                  <div className="model-add-input">
                    <label>Warranty Period :</label>
                    <input
                      type="text"
                      placeholder="e.g., 6 months, 1 year"
                      value={newModel.warranty_period}
                      onChange={(e) => setNewModel({ ...newModel, warranty_period: e.target.value })}
                    />
                  </div>

                </form>
                <button className="model-popup-save" onClick={handleAddNewModel}>
                  Save
                </button>
              </div>
            </div>
          )}


          {editPopup && (
            <div className="model-edit-popup-overlay">
              <div className="model-edit-popup-content">
                <button
                  className="model-edit-popup-close"
                  type="button"
                  onClick={() => setEditPopup(false)}
                >
                  <i className="fa-solid fa-x"></i>
                </button>
                <div className="model-raw-details-main">
                  <DataTable
                    columns={[
                      {
                        name: 'ID',
                        selector: (row, index) => index + 1, sortable: true
                      },
                      {
                        name: "Image",
                        selector: (row) => row.image,
                        cell: (row) => (
                          <img
                            src={row.image}
                            alt={row.name || "Raw Material"}
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        ),
                        sortable: false,
                      },
                      { name: "Component", selector: (row) => row.component, sortable: true },
                      { name: "Required Quantity", selector: (row) => row.required_qty, sortable: true },
                      {
                        name: "Action",
                        cell: (row) => (

                          <div className="device-action-btn">
                            <button className="action-button" onClick={() => {
                              // Assuming each raw material is part of the row object
                              const { model_id, id } = row; // Extract model_id and raw_material_id from the row
                              setEditData({ modelId: model_id, rawMaterialId: id }); // Set them in editData
                              setActionPopup(true); // Open the popup
                              { console.log(row) }
                            }}>
                              <i className="fa-solid fa-pen-to-square"></i>
                            </button>
                            <button className="action-button" onClick={() => handlerawDeleteClick(row)} >
                              <i className="fa-solid fa-trash-can"></i>
                            </button>
                          </div>
                        ),
                      },
                    ]}
                    data={rawMaterials}
                    fixedHeader
                  />
                </div>
                <div className="model-raw-add-main">
                  <div className="model-raw-add-head">
                    <p>Add Raw Material</p>
                  </div>
                  <div className="model-raw-inputs">
                    <select
                      className="model-popup-select"
                      value={selectedComponent}
                      onChange={(e) => setSelectedComponent(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Component
                      </option>
                      {components.map((data) => (
                        <option key={data.id} value={data.id}>
                          {data.component}
                        </option>
                      ))}
                    </select>


                    <input
                      type="number"
                      placeholder="Required Qty"
                      value={requiredQty}
                      onChange={(e) => setRequiredQty(e.target.value)}
                    />
                    <button onClick={() => handleAddRawMaterial(modelId)}>ADD</button>
                  </div>
                </div>

              </div>
            </div>
          )}
          {actionPopup && (
            <div className="action-popup-overlay">
              <div className="action-popup-content">
                <button
                  className="action-popup-close"
                  type="button"
                  onClick={handleCloseActionPopup}
                >
                  <i className="fa-solid fa-x"></i>
                </button>
                <div className="action-popup-input">
                  <label htmlFor="">Required Qty :</label>
                  <input
                    type="number"
                    min={0}
                    value={Qty} // Bind the input value to Qty state
                    onChange={(e) => setQty(e.target.value)} // Update the state when the input changes
                  />
                </div>
                <button
                  className="action-confirm-button"
                  onClick={() => handleSaveActionPopup()} // Call handleSaveActionPopup without arguments, it will use the current state
                >
                  Save
                </button>
              </div>
            </div>
          )}
          {showrawDeletePopup && (
            <div className="delete-raw-popup">
              <div className="delete-raw-content">
                <p>Are you sure you want to delete this raw material?</p>
                <p>{selectedRawMaterial?.component}</p>
                <div className="delete-popup-btn">
                  < button className="delete-popup-confirm" onClick={handlerawDeleteConfirm}>Confirm</button>
                  <button className="delete-popup-cancel" onClick={handleClosePopup}>Cancel</button>
                </div>

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Devices;
